import React from "react"
import { graphql } from "gatsby"
import "../components/queries/fragments-regions"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import Charts from "../components/layout/content/charts"

const regionsInfoPage = ( { data: { allItems: { nodes }}} ) => {
  /**
   * 
   * This page is only for generating content what I'll insert into Drupal static pages. It is not linked through web.
   * 
   */
  
  const sortingFields = [
    'field_population_total',
    'field_size_total',
    'field_population_density_total',
    'field_population_avg_age',
    'field_population_index_old_age'
  ]

  const fullChartPath = '/regiony-turistickeho-ruchu/zoznam-regionov-'
  const linkName = "Zoznam regiónov cestovného ruchu"

  return (
  <Layout>
      <Seo title="Uvodna stranka" />
      <br /><br /><br />
      <h2>Rebríčky regiónov cestovného ruchu</h2>
      <p>Celkový počet regiónov cestovného ruchu: {nodes.length}</p>
      <br />
      {
        sortingFields.map(item => 
          <Charts arr={nodes} field={item} chartPath={fullChartPath} linkName={linkName} />
      )}
    </Layout>    
  )
}

export default regionsInfoPage

export const query = graphql`
  query {
    allItems: allTaxonomyTermRegions {
            nodes {
                    ...chartsRegionsQueries
                }
        }
    }
`
